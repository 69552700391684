import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/common/header"
import { Text, Div, Tag, Row, Col, Image, Link, Container } from "atomize"
import Footer from "../components/homepage/Footer"

const Refund = () => {
  return (
    <Layout>
      <Header layout="layout-2" />

      <div style={{ height: "36px" }} />
      <Div
        m="auto"
        p={{
          t: { xs: "6rem", md: "10rem" },
          l: { xs: "1.5rem", md: "2rem" },
          r: { xs: "1.5rem", md: "2rem" },
        }}
        flexDir="row"
        maxW={"800px"}
      >
        <Text
          textSize="display1"
          textWeight="700"
          fontFamily="secondary"
          color="info700"
          m={{ b: "1rem" }}
        >
          Chính sách hoàn trả
        </Text>
        <p>
          Số dư dịch vụ uCall trong tài khoản của bạn được hiểu gồm 3 phần: Số
          tiền chưa sử dụng (1) + Số tiền cước viễn thông (2) + Số tiền phí sử
          dụng uCall (3).
        </p>
        <ul>
          <li>Chúng tôi chỉ có nghĩa vụ hoàn lại số tiền (1) và (3).</li>
          <li>
            Đối với số tiền (1), bạn sẽ được hoàn lại đầy đủ số tiền là (1) - số
            tiền khuyến mãi đã nhận.
          </li>
          <li>
            Đối với số tiền (3), chúng tôi sẽ chỉ có nghĩa vụ hoàn lại trong 7
            ngày kể từ ngày bạn bắt đầu sử dụng uCall lần đầu tiên.
          </li>
        </ul>
        <p>
          Mọi yêu cầu hoàn tiền vui lòng gửi email đến địa chỉ:
          contact@ucall.asia hoặc liên hệ trực tiếp qua box chat messenger trên
          trang chủ uCall
        </p>

        <div style={{ height: "128px" }} />
      </Div>
      <Footer />
    </Layout>
  )
}

export default Refund
